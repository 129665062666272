import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)

let url = "https://192.168.2.140:27017";

export default new Vuex.Store({
  state: {
    styles: [],
  },
  getters: {
    getStyles: state => state.styles,
  },
  actions: {
    async loadStyles({ commit }) {
      const response = await axios.get(url+`/styles`);
      console.log(response);
      commit('setStyles', response.data);
    },
  },
  mutations: {
    setStyles: (state, styles) => (state.styles = styles),
  },
  modules: {
  }
});
