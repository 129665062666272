<template>
    <img :src="src" :alt="alt" width="80px" />
</template>

<script>
import axios from "axios";
// import Vue from 'vue';
//import {mapActions, mapGetters} from 'vuex';
export default {
  name: 'AsyncImage',
  props: {
            selStyle: {
                type: String,
                required: true
            },
            serverUrl: {
                type: String,
                required: true
            },
    },
  data: function () {
    return { 
      src: "",
      alt: "",
    };
  },
  methods: {
      getImage: function(){
        //console.log(`https://192.168.2.140:27017/styles?style=` + this.selStyle + `-thumbnail`);
        axios({
            method: 'get',
            url: this.serverUrl + this.selStyle + `-thumbnail`,
            headers:{
                    //'Authorization' : `${token}`,
                    //'content-type': 'image/jpg',
                    //'accept': 'image/jpg'
            },
            responseType: 'blob',
        }).then((res) => {
            if((res.data.type == "image/jpeg" || res.data.type == "image/png") && res.data.size > 0){
                console.log(res);
                var urlCreator = window.URL || window.webkitURL;
                var imageUrl = urlCreator.createObjectURL( res.data );
                console.log(imageUrl);
                this.alt = this.selStyle;
                this.src = imageUrl;
            } else {

                console.log("no image as response: " + this.selStyle);
                setTimeout(() => {this.getImage()}, 1000);
            }
        }).catch((error) => {
            console.log(error);
        });
  
      }
  },
  created() {
    console.log("created " + this.selStyle);
    this.getImage();
    }
}
</script>