<template>
  <div id="app">
    <div>
      <h1>Neural Style Transfer</h1>
    </div>
    <div>
      <h2>Bild auswählen:</h2>
      <file-pond
        @processfile="onProcessFile" 
        @addfile="onAddFile"
        name="file"
        ref="pond"
        label-idle="Drop image here..."
        :allow-multiple="false"
        accepted-file-types="image/jpeg, image/png"
       
        :init="handleFilePondInit"
      />
    </div>
    <div>
      <h2>Style auswählen:</h2>
      <div style="display: flex; flex-wrap: wrap; justify-content: center; background-color:#f1f0ef; border-radius:10px;"> <!-- justify-content: space-between; -->
        <div v-for="(style) in this.getStyles" :key="style" style="display: flex; flex-direction: column; align-items: center; padding: 12px; margin-top: auto; ">
          <async-image  :selStyle="style" :serverUrl="`https://192.168.2.140:27017/styles?style=`" />
          <!-- <img src="./assets/logo.png" /> -->
          <!-- <img :src="getThumbnail" :alt='style' /> -->
          <p style="">{{style}}</p>
          <input @change="newStyle" type="radio" v-model="selectedStyle" :value="style" >
        </div>
      </div>
        
        <!-- <br />
        <span>Selected Style: {{selectedStyle}}</span> -->
    </div>
    <!-- </form> -->
    <div style="margin: 15px 30px">
      <button style="border: none; padding: 15px 20px" @click="styleTransfer()">Transfer Style</button>
    </div>
    <div>
      <h2>Result</h2>
      <img id="wait" src="./assets/wait.gif" v-if="wait" />
      <img :src="resultImage" />
    </div>
  </div>
</template>

<script>
// import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';
// Import Vue FilePond
import vueFilePond from "vue-filepond";
import axios from "axios";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
// import HelloWorld from './components/HelloWorld.vue'
// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import AsyncImage from './components/AsyncImage.vue';

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

export default {
  name: 'App',
  components: {
    FilePond,
    "async-image": AsyncImage,
  },
  data: function () {
    return { 
      myFile: null,
      resultImage: null,
      selectedStyle: "wave",
      scale: 1.0,
      wait: false,
      serverUrl: "`https://192.168.2.140:27017`",
    };
  },
  methods: {
    ...mapActions(['loadStyles']),
    handleFilePondInit: function () {
      console.log("FilePond has initialized");

      


      //this.$refs.pond.
      // FilePond instance methods are available on `this.$refs.pond`
    },
    onProcessFile: function(error, file){
      console.log('file processed', { error, file });
    },
    onAddFile: function(error, file){
      console.log('file added', { error, file });
      if(file){
        this.myFile = file.file;
        // this.wait = true;
        // start styleTransfer:
        //this.styleTransfer();
      }
    },
    newStyle: function(){
      if(this.myFile){
        this.styleTransfer();
      }
    },
    styleTransfer: function (){
      // show wait
      this.wait = true;

      let data = new FormData();
      data.append('file', this.myFile, this.myFile.name);
      data.append('style', this.selectedStyle);
      data.append('scale', this.scale);
      
      axios({
        method: 'post',
        url: "https://192.168.2.140:27017/stylize", /*?style=${this.selectedStyle}`,*/
        data: data,
      }).then(res => {

        console.log(res);
        console.log(res.request.response);
        let _data = new FormData();
        _data.append('taskID', res.request.response);
        
        setTimeout(() => {
          this.getImage(res.request.response)
          }, 2000);
      });
      
    },
    getImage: function(data){
      axios({
          method: 'get',
          url: "https://192.168.2.140:27017/stylize?taskID=" + data,
          //data: data,
          headers:{
                //'Authorization' : `${token}`,
                //'content-type': 'image/jpg',
                //'accept': 'image/jpg'
          },
          responseType: 'blob',
      }).then((res) => {
        if(res.data.type == "image/jpeg" && res.data.size > 0){
          console.log(res);
          var urlCreator = window.URL || window.webkitURL;
          var imageUrl = urlCreator.createObjectURL( res.data );

          this.wait = false;

          this.resultImage = imageUrl;  
        } else {
          setTimeout(() => {
            this.getImage(data)
            }, 2000);
        }
      }).catch((error) => {
        console.log(error)
      });
    },
  },
  computed: {
     ...mapGetters(['getStyles']),
  },
  created(){
    this.loadStyles();
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
p {
  margin: 0.2em;
  padding-top: 2px;
}
</style>